import {json} from '@remix-run/node';
import {Form, useActionData, useLoaderData} from '@remix-run/react';
import {
  Button,
  Card,
  FormLayout,
  Page,
  AppProvider as PolarisAppProvider,
  Text,
  TextField,
} from '@shopify/polaris-internal';
import polarisTranslations from '@shopify/polaris-internal/locales/en.json';
import polarisStyles from '@shopify/polaris-internal/styles.css?url';
import {useI18n} from '@shopify-internal/react-i18n';
import React, {useState} from 'react';

import {createI18nConfig} from '~/utils/createI18nConfig';

import {login} from '../../shopify.server';

import {loginErrorMessage} from './error.server';
import translations from './translations';

import type {ActionFunctionArgs, LoaderFunctionArgs} from '@remix-run/node';

export const links = () => [{rel: 'stylesheet', href: polarisStyles}];

export const loader = async ({request}: LoaderFunctionArgs) => {
  const errors = loginErrorMessage(await login(request));

  return json({errors, polarisTranslations});
};

export const action = async ({request}: ActionFunctionArgs) => {
  const errors = loginErrorMessage(await login(request));

  return json({
    errors,
  });
};

export default function Auth() {
  const loaderData = useLoaderData<typeof loader>();
  const actionData = useActionData<typeof action>();
  const [shop, setShop] = useState('');
  const {errors} = actionData || loaderData;

  const [i18n] = useI18n(createI18nConfig(translations));

  return (
    <PolarisAppProvider i18n={loaderData.polarisTranslations}>
      <Page>
        <Card>
          <Form method="post">
            <FormLayout>
              <Text variant="headingMd" as="h2">
                {i18n.translate('Login.login')}
              </Text>
              <TextField
                type="text"
                name="shop"
                label={i18n.translate('Login.domain')}
                helpText="example.myshopify.com"
                value={shop}
                onChange={setShop}
                autoComplete="on"
                error={errors.shop}
              />
              <Button submit>{i18n.translate('Login.login')}</Button>
            </FormLayout>
          </Form>
        </Card>
      </Page>
    </PolarisAppProvider>
  );
}
